import React, {useEffect} from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import * as FaIcons from 'react-icons/fa' 

import { SidebarData } from './SidebarData'

import styled from 'styled-components'

import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from "react-router-dom"

const Navbar = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    height: 3.5rem;
    background-color: #000080;
`

const MenuIconOpen = styled(Link)`
    display: flex;
    justify-content: start;
    font-size: 1.5rem;
    margin-left: 2rem;
    color: #ffffff;
`

const MenuIconClose = styled(Link)`
    display: flex;
    justify-content: end;
    font-size: 1.5rem;
    margin-top: 0.75rem;
    margin-right: 1rem;
    color: #ffffff;
`

const SidebarMenu = styled.div<{close: boolean}>`
    width: 250px;
    height: 100vh;
    background-color: #000080;
    position: fixed;
    top: 0;
    left: ${({ close}) => close ? '0' : '-100%'};
    transition: .6s;
`

const MenuItems = styled.li`
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    height: 90px;
    padding: 1rem 0 1.25rem;
`

const MenuItemLinks = styled(Link)`
    display: flex;
    align-items: center;
    padding: 0 2rem;
    font-size: 20px;
    text-decoration: none;
    color: #ffffff;

    &:hover {
        background-color: #ffffff;
        color: #000080;
        width: 100%;
        height: 45px;
        text-align: center;
        border-radius: 5px;
        margin: 0 2rem;
    }
`

const Sidebar: React.FunctionComponent = () => {
    const [close, setClose] = useState(false);
    const showSidebar = () => setClose(!close);

    const[userInput, setUserInput] = useState(""); 
    const [userName, setUserName] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const userJson = localStorage.getItem("isLogged");
        setUserName(userJson === null ? "" : userJson);
    });

    const LoginButtonClick = (props:any)=>{ 
        if(props.target.innerText === "Login"){
            if(userInput == ""){
                setUserName("");
                localStorage.removeItem("isLogged");
                navigate('/');
            } else {
                setUserName(userInput);
                localStorage.setItem("isLogged", JSON.stringify(userInput));
            }
        } else {
            setUserName("");
            setUserInput("");
            localStorage.removeItem("isLogged");
            props.target.innerText = "";
            navigate('/');

        }
    }


    return (
        <>
            <Navbar>
                <MenuIconOpen to="#" onClick={showSidebar}>
                    <FaIcons.FaBars />
                </MenuIconOpen>
                <div style={{color: 'white', width: '275px', marginLeft: 'auto', marginRight: '0'}}>
                {userName == "" ? <input value={userInput} onChange={(e) => {setUserInput(e.target.value)}} style={{width: '200px', height: '30px', float: 'left'}} type="text" className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1"/> : null}
                {userName == "" ? <Button variant="outline-light" onClick={(e)=>{LoginButtonClick(e)}} style={{height:'30px', marginLeft: '5px', lineHeight: '15px'}}>Login</Button> : null}
                
                {userName != "" ? <Button style={{height:'30px', lineHeight: '15px', marginRight: '5px', float: 'right'}} variant="primary" onClick={(e)=>{LoginButtonClick(e)}}>Logout</Button>: null}
                {userName != "" ? <div style={{height: '30px', lineHeight: '30px', float: 'right', marginRight: '10px'}}>{userName}</div> : null}


                </div>
            </Navbar>
            
            <SidebarMenu close={close}>
                <MenuIconClose to="#" onClick={showSidebar}>
                    <FaIcons.FaTimes />
                </MenuIconClose>

                {SidebarData.map((item, index) => {
                    if((userName != "") || (item.title == "Home")){
                        return (
                            <MenuItems key={index}>
                                <MenuItemLinks to={item.path}>
                                    {item.icon}
                                    <span style={{marginLeft: '16px'}}>{item.title}</span>
                                </MenuItemLinks>
                            </MenuItems>
                        )
                    }

                })}
            </SidebarMenu>
        </>
    )
}

export default Sidebar
