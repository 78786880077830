import React from 'react'
import * as FaIcons from 'react-icons/fa' 

export const SidebarData = [
    {
        title: 'Home',
        path: '/',
        icon: <FaIcons.FaHome />
    },
    {
        title: 'Vendor',
        path: '/vendor',
        icon: <FaIcons.FaUsers />
    },
    {
        title: 'Cve',
        path: '/cve',
        icon: <FaIcons.FaTasks />
    },
    {
        title: 'Admin',
        path: '/admin',
        icon: <FaIcons.FaRegChartBar />
    }
]
