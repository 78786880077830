import React from 'react'
import { useState, useEffect } from 'react'
import styled from 'styled-components'

const TeamText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    height: 70vh;
`

const Vendor: React.FunctionComponent = () => {
    const [userName, setUserName] = useState("");

    useEffect(() => {
        const userJson = localStorage.getItem('isLogged');
        setUserName(userJson == null ? "" : userJson);
    });

    return (
        <TeamText>Vendors: {userName == "" ? "Not Logged In" : userName + " Logged in"}</TeamText>
    )
}

export default Vendor
