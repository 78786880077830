import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const AnalyticsText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    height: 70vh;
`

const Admin: React.FunctionComponent = () => {
    const [userName, setUserName] = useState("");

    useEffect(() => {
        const userJson = localStorage.getItem('isLogged');
        setUserName(userJson == null ? "" : userJson);
    });
    return (
        <AnalyticsText>Admin: {userName == "" ? "Not Logged In" : userName + " Logged in"}</AnalyticsText>
    )
}

export default Admin
